import React from "react"
import PageHeader from "../../components/common/PageHeader"
import Reports from "../../components/financial/Reports"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import HeaderFeaturedImage from "../../components/common/HeaderFeaturedImage"

export default function FinancialReportsPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 49 }) {
        id
        title
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const { title, featuredImage, content } = data.wpPage

  return (
    <Layout>
      <SEO title="Financial Reports" />
      <div>
        {featuredImage && (
          <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
        )}
        <div
          id="financial-reports"
          className="max-w-6xl mx-auto py-8 lg:py-16 px-4 lg:px-8"
        >
          <div className="flex flex-col gap-8">
            {!featuredImage ? <PageHeader text={title} /> : null}
            <Reports />
          </div>
          <div className="mfn-widget hidden">
            <table className="">
              <tbody>
                <td className="mfn-archive-header mfn-archive-header-item-type "></td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}
